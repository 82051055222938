import React, { Component } from 'react';
import Icofont from 'react-icofont';
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";

class Testimonials extends Component {
    render() {
        //Testimonials loop start
        const testimonialsitem = this.props.testimonialsData.map((testimonials, index) => (
            <div className="single-testimonial-item text-center" key={index}>
                <Icofont icon="icofont-quote-left" />
                <p>{testimonials.Content}</p>

                <div className="client-profile">
                    <img src={testimonials.clientImage} alt="client-one" />
                </div>

                <div className="client-info">
                    <h3>{testimonials.Name}</h3>
                    <span>{testimonials.Profession}</span>
                </div>
            </div>
        ));
        //Testimonials loop END
        return (
            <>
            <section className="testimonials ptb-100">
                <div className="container">
                    <OwlCarousel
                        className="owl-theme testimonial-slides"
                        items={1}
                        nav={true}
                        dots={false}
                        autoplay={false}
                        loop={true}
                        autoplayHoverPause={true}
                        smartSpeed={1000}
                        navText={[
                            "<i class='icofont-arrow-left'></i>",
                            "<i class='icofont-arrow-right'></i>"
                        ]}
                    >
                        {testimonialsitem}
                    </OwlCarousel>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
Testimonials.propTypes = {
    testimonialsData: PropTypes.array
};

//Default Props
Testimonials.defaultProps = {
    testimonialsData: [
        {
            clientImage: require("../assets/img/client-one.png"),
            Content: "I loved the convienence and the technology.",
            Name: "Matt",
            Profession: "Loyal Customer",
        },
        {
            clientImage: require("../assets/img/client-one.png"),
            Content: "Kombucha, Cold Press & other healthy products in a vending machine was the most LA thing I've seen in a while. I love it so much. The world is healing.",
            Name: "Jason",
            Profession: "First Time User",
        },
    ]
};

export default Testimonials;
