import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import ScrollAnimation from 'react-animate-on-scroll';

class FunFacts extends Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };
    render() {
        return (
            <>
            <section className="fun-facts ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="count-box text-center">
                                <div className="glyph">
                                    <Icofont icon="icofont-handshake-deal" />
                                </div>
                                <p>Total Product Vended</p>
                                <h2 className="counter" >
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 25000
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor>
                                    <span> +</span>
                                </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="count-box text-center">
                                <div className="glyph">
                                    <Icofont icon="icofont-checked" />
                                </div>
                                <p>Satisfied Customers</p>
                                <h2 className="counter">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 6000
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor>
                                    <span> +</span>
                                </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="count-box text-center">
                                <div className="glyph">
                                    <Icofont icon="icofont-trophy-alt" />
                                </div>
                                <p>SoCal Locations</p>
                                <h2 className="counter">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 14
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
FunFacts.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string
};

//Default Props
FunFacts.defaultProps = {
    SectionbgTitle: "Fun Facts",
    sectionTitle: "Some Fun Facts",
    sectionDescription:
        "Established in late 2015 from a couch in Sherman Oaks, CA.  NeighborNosh was born out of a desire to see healthy, affordable, and accessible food options become a reality for the modern lifestyle. Michael Rosenthal founder and CEO traveled the world working in the music as a tour manager.  He noticed an opportunity calling. As food and health trends show, local, organic, and convenient food needs are on the rise. However, an on the go lifestyle is often limited to nutrient deficient fast food choices, outdated vending machines packed with junk food and sugary corn- syrup drinks, or 24 hour diner options that just take too much time. NeighborNosh would serve the world by offering 24/7 access, healthy and affordable chef- prepared meals stocked frequently with a rotating selection, convenient locations, and innovative technology to make ordering and pick up a breeze.",
};

export default FunFacts;
