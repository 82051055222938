import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export class ItemTwo extends Component {
    render() {
        //Start ItemTwo Loop
        const itemtwodata = this.props.itemtwosData.map((itemtwo, index) => (

            <div className={itemtwo.className} key={index}>
                <div className={itemtwo.FeaturedClass}>
                    <span className="featured">{itemtwo.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemtwo.planName}</h3>
                    <span className="value">{itemtwo.price}</span>
                    <span className="period">{itemtwo.description}</span>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemtwo.content1}</li>
                        <li>{itemtwo.content2}</li>
                        <li>{itemtwo.content3}</li>
                        <li>{itemtwo.content4}</li>
                        <li>{itemtwo.content5}</li>
                        <li>{itemtwo.content6}</li>
                        <li>{itemtwo.content7}</li>
                        <li>{itemtwo.content8}</li>
                    </ul>
                    <div className="center-wrap">
                        <Link to={itemtwo.btnlink} className="btn-a">
                            <div className="button">
                                {itemtwo.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                                <div className="mask" /></div>
                        </Link>
                    </div>
                </div>
            </div>

        ));
        //End ItemTwo Loop
        return (
            <>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemtwodata}
                </div>
            </div>
            </>
        );
    }
}
ItemTwo.PropsTypes = {
    itemtwosData: PropTypes.array
};
ItemTwo.defaultProps = {
    itemtwosData: [
        {
            planName: "SIDE HUSTLE",
            className: "plan",
            description: "We partner with small business persons and restaurant owners to place healthy machines in convient locations to create additional revenue stream. Currently being offered nationwide",
            FeaturedClass: "",
            Featured: "",
            price: "$115K",
            content1: "8 NeighborNosh Smart Combo Vending Machines",
            content2: "iOS and Android App.",
            content3: "Location finder support",
            content4: "Regionally Curated Wholesale Products",
            content5: "NeighborNosh Operations Website",
            content6: "Monthly Marketing Programs",
            content7: "Discounted additional machines and more.",
            BtnName: "Join Now",
            btnlink: "/#0",
        },
        {
            planName: "ENTREPRENEUR",
            className: "plan featured",
            description: "Run your own vendng business.  This turn key opportunity is for the more serious entrepreneur who see's the vision of automated retail. Currently being offered nationwide.",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
            price: "$245K",
            content1: "20 Vending Machines",
            content2: "Customized POS",
            content3: "iOS and Android App.",
            content4: "High Capacity Machines",
            content5: "Remote Access & Reporting",
            content6: "Event & Marketing Revenue Opportunities",
            content7: "Enhanced Security Available",
            content8: "White Label Corporate Partnerships Available",
            BtnName: "Franchise Now",
            btnlink: "/#0",
        },
        {
            planName: "REGIONAL PARTNER",
            className: "plan",
            description: "Expand your restaurant or your own vendng business. This turn key opportunity comes with enough machines to take over any regional market with minimal staff.  Use our automated retail solution for any business you dream up. Currently being offered worldwide.",
            FeaturedClass: "",
            Featured: "",
            price: "$500K",
            content1: "40 Vending Machines",
            content2: "Corporate Custim Branding Available",
            content3: "Custom backend admin dashboard",
            content4: "Location Finder Assistance",
            content5: "iOS and Android App.",
            content6: "Regionally Curated Wholesale Products",
            BtnName: "Start Now",
            btnlink: "/#0",
        },

    ]
};
export default ItemTwo;
