import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export class ItemOne extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (

            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                    <span className="value">{itemone.price}</span>
                    <span className="period">{itemone.description}</span>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemone.content1}</li>
                        <li>{itemone.content2}</li>
                        <li>{itemone.content3}</li>
                        <li>{itemone.content4}</li>
                        <li>{itemone.content5}</li>
                        <li>{itemone.content6}</li>
                        <li>{itemone.content7}</li>
                        <li>{itemone.content8}</li>
                    </ul>
                    <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                                <div className="mask" /></div>
                        </Link>
                    </div>
                </div>
            </div>

        ));
        //End ItemOne Loop
        return (
            <>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}
                </div>
            </div>
            </>
        );
    }
}
ItemOne.PropsTypes = {
    itemonesData: PropTypes.array
};
ItemOne.defaultProps = {
    itemonesData: [
        {
            planName: "BASIC",
            className: "plan",
            description: "Includes a combo smart vending machine with the free vend white label software",
            FeaturedClass: "",
            Featured: "",
            price: "$9,500",
            content1: "Automate your event experience",
            content2: "24/7 Remote Supprt",
            content3: "Vend Multiple Products",
            content4: "Custom Branding",
            content5: "Fast Build Turnaround",
            BtnName: "Rent Now",
            btnlink: "/#0 ",
        },
        {
            planName: "EXTENDED",
            className: "plan featured",
            description: "All of our basic package technology plus added custom software options designed to be used for multiple events.",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
            price: "$14,500",
            content1: "Automate Your Give-a-way or VIP Experience",
            content2: "Combo or Plus Capacity Machine",
            content3: "Vend mupltiple products.",
            content4: "Custom Touchscreen branding Opportunities",
            content5: "Collect Statistics & Analytics",
            content6: "Social Media intigrations available",
            content7: "Enhanced Security",
            BtnName: "Lease Now",
            btnlink: "/#0",
        },
        {
            planName: "CUSTOM",
            className: "plan",
            description: "Full customs builds are welcomed.  We love to innovate, schedule am appointment with an engineers now!",
            FeaturedClass: "",
            Featured: "",
            price: "$20,000+",
            content1: "Build your dream vending machine with us!",
            content2: "Exterior fabrication & design",
            content3: "Custom software design",
            content4: "Software gamification",
            content5: "You dream it, we build it, they vend it!",
            BtnName: "Build Now",
            btnlink: "/#0",
        },

    ]
};
export default ItemOne;
