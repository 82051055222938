import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import ScrollAnimation from 'react-animate-on-scroll';

class About extends Component {
    render() {
        //About loop start
        const aboutdata = this.props.aboutsData.map((about, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
                <div className="about-info">
                    <Icofont icon={about.Icon} />
                    <h3>{about.title}</h3>
                    <p>{about.content}</p>
                </div>
            </div>
        ));
        //About loop END
        return (
            <>
            <section id="about" className="about-us ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row">
                        {aboutdata}
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    aboutsData: PropTypes.array
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "About",
    sectionTitle: "About Us",
    sectionDescription:
        "Distributing local quality foods via technology and vending machines.",
    aboutsData: [
        {
            Icon: "icofont-binoculars",
            title: "Company Background",
            content: "Built out neccessity and a desire to see healthy, affordable, and accessible foods for the fast modern lifestyle.",
        },
        {
            Icon: "icofont-direction-sign",
            title: "Our Approach",
            content: "We side with the local underdogs, using our technology and algorythms we order, distribute and share our analytics with our local partners to curate the most convienet and affordable path for getting your proucts into market.",
        },
        {
            Icon: "icofont-grocery",
            title: "Vend With Us",
            content: "We specialize in local products. Submit your products for our vending machine route and distribute program.  Reach out to our sales team to have us distribute to local restuarants, commissaries, food trucks and through our vending network.",
        },
        {
            Icon: "icofont-location-pin",
            title: "Host a Machine",
            content: "We are accepting applications for all types of locations including gyms, large apartment complex's, strip malls and more.",
        },
        {
            Icon: "icofont-sale-discount",
            title: "Increase your Margins",
            content: "Lower your overhead, manage more square footage and more area codes with less using AI and automated retail.",
        },
        {
            Icon: "icofont-bank",
            title: "Invest with Us",
            content: "You can be a part of the newest vending machine revolution.  Grow with us as an equity partner or open your own vending routes.  Ask our sales team about our current investment opportunities.",
        },
    ]
};

export default About;
