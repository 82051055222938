import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
    render() {
        //Service loop start
        const servicedata = this.props.servicesData.map((service, index) => (
            <div className="col-md-6 col-lg-4 text-center" key={index}>
                <div className="service-item">
                    <div className="glyph">
                        <Icofont icon={service.icon} />
                    </div>
                    <h3>{service.heading}</h3>
                    <p>{service.description}</p>
                </div>
            </div>
        ));
        //Service loop END
        return (
            <>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Vending",
    sectionTitle: "Vending Services",
    sectionDescription:
        "NeighborNosh believes that food on-the-go can be food with integrity that is both healthy and delicious.  At its core the NeighborNosh brand brings together local products, convenience and quality food and beverages via the culmination of creative culinary entrepreneurs, technology and smart vending machines.  The result? Quality options in your neighborhood to serve your busy lifestyle in a meaningful way.",

    servicesData: [
        {
            icon: "icofont-automation",
            heading: "Automated Retail Solutions",
            description:
                "Operate more square footage with minimal staffing needs. We strive to embrace the new age of automation and drive change in our industry which allows us to keep our clients retail technology relevant."
        },
        {
            icon: "icofont-food-cart",
            heading: "Local Products",
            description:
                "We believe in local and working with our neighborhoods and communities.  Passionate, healthy, vegan, paleo, creative, small batch specilty brands, etc.  Ask our sales team about our full line of local wholesale brand partners."
        },
        {
            icon: "icofont-woman-in-glasses",
            heading: "Live Support 24/7",
            description:
                "Even though we are robots we understand human connection so we made sure that you can talk to a human whenever you need.  Dial 1-844-634-NOSH 24/7 for any of your questions, comments, ideas or just to chit chat."
        },
        {
            icon: "icofont-chart-growth",
            heading: "Strategic Partnerships & Advertising",
            description:
                "Promote your event, movie or product using the reach our SoCal vending fleet and capture a variety of demographics 24/7. We have programs to fit your budget and goals for the ultimate ROI."
        },
        {
            icon: "icofont-electron",
            heading: "Experiential & Event Marketing",
            description:
                "We strive to embrace and drive change in our industry which allows us to keep our clients relevant."
        },
        {
            icon: "icofont-laptop-alt",
            heading: "Custom Vending Machine Design & Development",
            description:
                "We strive to embrace and drive change in our industry which allows us to keep our clients relevant."
        },
    ]
};

export default Services;
