import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';

class Footer extends Component {
    render() {
        return (
            <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <p className="copyright">{this.props.copyrightText}</p>
                        </div>
                        <div className="col-md-7">
                            <div className="social-icons bottom">
                                <ul className="list-inline">
                                    <li>{this.props.socialTitle} </li>
                                    <li>
                                        <a href={this.props.FacebookLink} rel="noopener noreferrer" target="_blank"><Icofont icon="icofont-facebook" /></a>
                                    </li>
                                    <li>
                                        <a href={this.props.TwitterLink} rel="noopener noreferrer" target="_blank"><Icofont icon="icofont-twitter" /></a>
                                    </li>
                                    <li>
                                        <a href={this.props.InstagramLink} rel="noopener noreferrer" target="_blank"><Icofont icon="icofont-instagram" /></a>
                                    </li>
                                    <li>
                                        <a href={this.props.linkedinLink} rel="noopener noreferrer" target="_blank"><Icofont icon="icofont-linkedin" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            </>
        );
    }
}
//Props Types
Footer.propTypes = {
    copyrightText: PropTypes.string,
    FacebookLink: PropTypes.string,
    TwitterLink: PropTypes.string,
    InstagramLink: PropTypes.string,
    linkedinLink: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
    copyrightText: "© 2021 NeighborNosh Inc. All Rights Reserved.",
    socialTitle: "Follow Us On:",
    FacebookLink: "https://www.facebook.com/neighbornosh",
    TwitterLink: "https://twitter.com/neighbornosh",
    InstagramLink: "https://www.instagram.com/neighbornosh",
    linkedinLink: "https://www.linkedin.com/neighbornosh",
};
export default Footer;
