import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import { Accordion, AccordionItem } from "react-sanfona";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

class FAQ extends Component {
    render() {
        return (
            <>
            <section className="faq ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="faq-content">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <Link to={this.props.ContentLink}>
                                    <div className="content-box color-effect-1">
                                        <h3>{this.props.ContentTitle}</h3>

                                        <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                            <div className="box-icon"><Icofont icon="icofont-share-alt" /></div>
                                        </div>

                                        <p>{this.props.ContentDescription}</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-8">
                                <Accordion
                                    rootTag="div"
                                    className="panel-group"
                                >
                                    {this.props.faqData.map(item => {
                                        return (
                                            <AccordionItem
                                                key={item}
                                                title={item.title}
                                                expanded={true}
                                                expandedClassName=""
                                                className="panel-title panel panel-default"
                                                titleTag="a"
                                                titleClassName=""
                                            >
                                                <div>
                                                    <div className="panel-body">
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
FAQ.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    ContentLink: PropTypes.string,
    ContentTitle: PropTypes.string,
    ContentDescription: PropTypes.string,
    faqData: PropTypes.array
};

//Default Props
FAQ.defaultProps = {
    SectionbgTitle: "FAQ",
    sectionTitle: "Frequntly Ask & Questions",
    sectionDescription:
        "Life's confusing. We get it, we are vending machines and our goal is to avoid some of those mindless human interactions. We know you will have questions so we coded this section just for you!",

    ContentTitle: "Have More Questions?",
    ContentDescription: "If you have more questions, send us a message and we will answer you as soon as possible.",
    ContentLink: "/#0",

    faqData: [
        {
            title: "How can I download your app?",
            description: "Our App is currently available on the iOS App. Store.  The App allows you to see the locations and items in each vending machin.  Android version is expected to be released in January 2022.",
        },
        {
            title: "What does it mean to reserve a product?",
            description: "The NeighborNosh App allows you to reserve your product for up to an hour to guarentee you vend what you want when you want it.",
        },
        {
            title: "What is Live Support?",
            description: "We have a 100 percent satisfaction guarentee.  Call live support 24/7 by calling 1-844-634-NOSH (7764).",
        },
        {
            title: "Are you hiring?",
            description: "Always! Do you love vending machines, do you want to develop a healthy food product, are you obsessed with the outside.  Join our team.",
        }
    ]
};

export default FAQ;
