import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

class Blog extends Component {
    componentDidMount() {
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({ scrollWithOffset });
    }
    render() {
        //Blog loop start
        const blogdata = this.props.blogsData.map((blog, index) => (
            <div className="col-md-6 col-lg-6" key={index}>
                <div className="blog-item">
                    <Link to={blog.postLink} className="blog-img"><img src={blog.postImage} alt="blog-one" /></Link>
                    <div className="blog-info">
                        <div className="date-box">
                            {blog.date} <span className="month">{blog.month}</span>
                        </div>
                        <div className="title-meta">
                            <h2><Link to={blog.postLink}>{blog.posttitle}</Link></h2>
                            <div className="post-meta">
                                <ul>
                                    <li><Icofont icon="icofont-funky-man" /> Produced with: <Link to={blog.authorLink}>{blog.authorName}</Link></li>
                                    <li><Icofont icon="icofont-speech-comments" /> Vends: <Link to={blog.CommentsLink}>{blog.TotalComments}</Link></li>
                                    <li><Icofont icon="icofont-tags" /> Category: <Link to={blog.TagLink}>{blog.TagName}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="post-content">
                        <p>{blog.postContent}</p>
                    </div>
                </div>
            </div>
        ));
        //Blog loop END
        return (
            <>
            <section id="blog" className="our-blog ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row">
                        {blogdata}
                        <div className="col-lg-12 col-md-12 all-post">
                            <div className="center-wrap">
                                <Link to={this.props.btnLink} className="btn-a">
                                    <div className="button">
                                        {this.props.BlogBtn} <Icofont icon="icofont-long-arrow-right" />
                                        <div className="mask"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}
//Props Types
Blog.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    btnLink: PropTypes.string,
    BlogBtn: PropTypes.string,
    blogsData: PropTypes.array
};

//Default Props
Blog.defaultProps = {
    SectionbgTitle: "Vending",
    sectionTitle: "CUSTOM BUILD CASE STUDIES",
    sectionDescription:
        "We are more than just NeighborNosh the healthiest of health food vending.  You can use our technology for your next event, tradeshow or red carpet experience.",
    btnLink: "/blog-one",
    BlogBtn: "View All Studies",
    blogsData: [
        {
            postImage: require("../assets/img/blog-one.jpg"),
            postLink: "/blog-details",
            date: "19'",
            month: "Feb",
            posttitle: "Full Custom Vending Machine.",
            postContent: "Custom double plus capacity vending machine with custom survey software.  This machine was built for Sprites 'Thirst for Yours' campaign introducing new flavors like Sprite Limonade and Sprite Cranberry.  We designed custom POS software that allowed customers to take a three question survey and receive a free sample in exchange for their email.  The user was also able to take a photo for social media at our machine",
            authorName: "Crown & Conquer",
            authorLink: "/#0",
            TotalComments: "10,000+",
            CommentsLink: "/#0",
            TagName: "Double Plus Custom Software Package",
            TagLink: "/#0",
        },
        {
            postImage: require("../assets/img/blog-two.jpg"),
            postLink: "/blog-details",
            date: "21'",
            month: "Feb",
            posttitle: "Automation, the marketing trend you need.",
            postContent: "With less than a three week turnaround before delivery, we got the challange to build custom software and rebrand a neighbornosh vending machine with the clients brand guidelines. We delivered the Celsius machine on time and vended more than 3,000 samples at two seperate trade shows over a span of 6 days.",
            authorName: "Noshware Tech",
            authorLink: "/#0",
            TotalComments: "6,500+",
            CommentsLink: "/#0",
            TagName: "Combo Machine Free Vend Basic Package",
            TagLink: "/#0",
        },
        /* 
        {
            postImage: require("../assets/img/blog-three.jpg"),
            postLink: "/blog-details",
            date: "30",
            month: "Jan",
            posttitle: "10 reasons you need a digital marketing strategy in 2019",
            postContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
            authorName: "Jone",
            authorLink: "/#0",
            TotalComments: "06",
            CommentsLink: "/#0",
            TagName: "lifestyle",
            TagLink: "/#0",
        },
        {
            postImage: require("../assets/img/blog-four.jpg"),
            postLink: "/blog-details",
            date: "20",
            month: "Jan",
            posttitle: "How to build a programming career.",
            postContent: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
            authorName: "Jone",
            authorLink: "/#0",
            TotalComments: "06",
            CommentsLink: "/#0",
            TagName: "lifestyle",
            TagLink: "/#0",
        },
    */

    ]
};

export default Blog;
