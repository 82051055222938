import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";

class BannerOne extends Component {
    render() {
        //BannerOne loop start
        const banneronedata = this.props.banneronesData.map(
            (bannerone, index) => (
                <div className={bannerone.BgClass} key={index}>
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <VisibilitySensor>
                                {({ isVisible }) => (
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <span
                                                    className={
                                                        isVisible
                                                            ? "hero-text animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {bannerone.TopTitle}
                                                </span>

                                                <h1
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {bannerone.Title}
                                                </h1>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {bannerone.Content}
                                                </p>
                                                <div className="center-wrap">
                                                    <Link to={bannerone.BtnLink} className="btn-a">
                                                        <div className="button">
                                                            {bannerone.BtnName}
                                                            <Icofont icon="icofont-long-arrow-right" />
                                                            <div className="mask" /></div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </div>
            )
        );
        //BannerOne loop END

        //Thumbs loop Start
        const thumbdata = this.props.thumbsData.map((thumb, index) => (
            <div className="owl-thumb-item" key={index}>
                <Icofont icon={thumb.ThumbIcon} />
                <h3>{thumb.ThumbTitle}</h3>
                <p>{thumb.ThumbContent}</p>
            </div>
        )
        );
        //Thumbs loop END
        return (
            <>
            <div id="home" className="hompage-slides-wrapper">
                <OwlCarousel
                    className="owl-theme homepage-slides"
                    items={1}
                    touchDrag={false}
                    margin={0}
                    mouseDrag={false}
                    smartSpeed={1000}
                    dotData={true}
                    dotsContainer={".owl-thumbs"}
                >
                    {banneronedata}
                </OwlCarousel>

                <div className="owl-thumbs">
                    {thumbdata}
                </div>
            </div>
            </>
        );
    }
}
//Props Types
BannerOne.propTypes = {
    banneronesData: PropTypes.array,
    thumbsData: PropTypes.array
};

//Default Props
BannerOne.defaultProps = {
    banneronesData: [
        {
            BgClass: "single-slider-item slide-bg-1",
            TopTitle: "Open 24/7",
            Title: "Healthy & Delicous",
            Content:
                "You can access most of our vending machines day and night.  If you can get to it, you can vend it.  Find your nearest machine now by downloading the free iOS or Android app!",
            BtnLink: "https://www.instagram.com/neighbornosh/",
            BtnName: "Find Locations"
        },
        {
            BgClass: "single-slider-item slide-bg-2",
            TopTitle: "Local & Healthy",
            Title: "Locally Sourced Products",
            Content:
                "NeighborNosh is a culinary marketplace where local chefs can reach consumers outside the traditional restaurant setting. This platform gives aspiring chefs a voice to better connect with foodies – in turn – giving food enthusiasts greater options, fresh food alternatives, a more personal dining experience with the simplicity of purchasing delicious meals through the innovative NeighborNosh distribution system.",
            BtnLink: "https://www.instagram.com/neighbornosh/",
            BtnName: "Find Products"
        },
        {
            BgClass: "single-slider-item slide-bg-3",
            TopTitle: "Live & Human",
            Title: "Locally sourced live customer service",
            Content:
                "NeighborNosh is a culinary marketplace where local chefs can reach consumers outside the traditional restaurant setting. This platform gives aspiring chefs a voice to better connect with foodies – in turn – giving food enthusiasts greater options, fresh food alternatives, a more personal dining experience with the simplicity of purchasing delicious meals through the innovative NeighborNosh distribution system.",
            BtnLink: "https://www.instagram.com/neighbornosh/",
            BtnName: "Contact Now"
        }
    ],
    thumbsData: [
        {
            ThumbIcon: "icofont-rocket-alt-1",
            ThumbTitle: "Innovative Designs",
            ThumbContent: "Quality vending robotics, safe and smoothe product dispensing.  Our revolutionary proprietary vending software adds a modern touch to every vend.",
        },
        {
            ThumbIcon: "icofont-star-alt-1",
            ThumbTitle: "Locally Sourced Products",
            ThumbContent: "We develop relationships with local culinary entrepreneurs to provide the perfectly healthy and small batch curated vending machines experience.",
        },
        {
            ThumbIcon: "icofont-live-support",
            ThumbTitle: "Live 24/7 Support",
            ThumbContent: " Dial 1-844-634-NOSH 24 hours 7 days a week for any of your questions, comments, ideas, products pitches or just to chit chat.",
        },
    ]
};

export default BannerOne;
