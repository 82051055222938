import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

class WelcomeServices extends Component {
    render() {
        return (
            <>
            <section className="cta-area cta-bg-img">
                <div className="diplay-table">
                    <div className="display-table-cell">
                        <div className="container">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="row">
                                    <div className="col-sm-6 col-md-8 col-lg-8">
                                        <div className="cta-info">
                                            <h2>{this.props.Title}</h2>
                                            <p>{this.props.Content}</p>
                                            <img className="img-fluid download-icon" src={this.props.ImageAppStore} alt="" />
                                            <div className="center-wrap">
                                                <Link to={this.props.BtnLink} className="btn-a">
                                                    <div className="button">
                                                        {this.props.BtnName}
                                                        <Icofont icon="icofont-long-arrow-right" />
                                                        <div className="mask" /></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-4 cta-img">
                                        <img className="img-fluid" src={this.props.Image} alt="mac-pro" />
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
WelcomeServices.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
    Image: PropTypes.string,
    ImageAppStore: PropTypes.string,

};

//Default Props
WelcomeServices.defaultProps = {
    Title: "Rated the best in class vending machine experience.",
    Content: "We offer a wide range of local products available 24/7 in the palm of your hand via our innovative iOS and Android app. or via any location through our touchscreen vending experience at every NeighborNosh location.",
    ImageAppStore: require("../assets/img/download-icons.png"),
    BtnLink: "/#0",
    BtnName: "DOWNLOAD NOW",
    Image: require("../assets/img/mac-pro.png"),
};
export default WelcomeServices;
