import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";

class Feature extends Component {
    render() {
        //Feature loop start
        const featuredata = this.props.featuresData.map((feature, index) => (
            <div className="col-lg-4 col-md-4" key={index}>
                <div className="single-feature">
                    <div className="glyph">
                        <Icofont icon={feature.icon} />
                    </div>
                    <h3>{feature.heading}</h3>
                    <p>{feature.description}</p>
                </div>
            </div>
        ));
        //Feature loop END
        return (
            <>
            <section className="feature-area">
                <div className="container">
                    <div className="row">
                        {featuredata}
                    </div>
                </div>
            </section>
            </>
        );
    }
}

//Props Types
Feature.propTypes = {
    featuresData: PropTypes.array
};

//Default Props
Feature.defaultProps = {
    featuresData: [
        {
            icon: "icofont-responsive",
            heading: "Innovative Designs",
            description:
                "We use the highest quality vending robotics on the market to integrate our revolutionary proprietary vending technology and software."
        },
        {
            icon: "icofont-brand-mercedes",
            heading: "Local Products, Locally.",
            description:
                "We combine modern vending and software technology innovation and a new standard for providing fresh and healthy food options. We serve up a platform that allows for locally sourced, delicious food choices to our community to accommodate their busy lifestyle."
        },
        {
            icon: "icofont-live-support",
            heading: "Reliable 24/7 Support",
            description:
                "We are available 24/7 for any need client to cusomer to vending partner."
        }
    ]
};

export default Feature;
